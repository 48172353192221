import Vue from 'vue';
import axios from 'axios';
import router from '../router';
import store from '../store';

const api = axios.create({
    baseURL: process.env.VUE_APP_ORIGINBACKENDBASEURL,
    headers: {
        'Content-Type': 'application/json'
        //'Cache': 'no-store' // todo: see if I can get it to play nice with CORS
    }
});

// global axios interceptors for all backend requests and responses
api.interceptors.request.use(
  function (config) {
    console.log(config.method);
    const token = store.state.authenticationToken;
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  });
  
api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if(error.response.status === 401) {
        router.push('/login');
      } else {
        Vue.prototype.$toastr.e(error.message);
      }
      return Promise.reject(error);
    });

Vue.prototype.$http = axios;

export default api;